<template>
  <div style="color: #a2a2a2">
    <div style="margin: 10px; padding: 0 0 10px; border-bottom: 1px dashed #ccc; color: #a6a6a6; font-weight: bold;font-size: 13px;">
      重置资料
    </div>
    <div v-show="step === 1" class="clear reset-step">
      <el-form :model="resetForm" status-icon :rules="step1rules" ref="step1rules" label-width="180px"
               class="demo-ruleForm" size="mini">
        <el-form-item label="申诉账号" prop="account">
          <el-col :span="9">
            <el-input v-model="resetForm.account" autocomplete="off" placeholder="手机号/帐号"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="回执码" prop="resetCode">
          <el-col :span="9">
            <el-input v-model="resetForm.resetCode" autocomplete="off" placeholder="回执码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="nextStep('step1rules')">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="step === 2" class="clear reset-step">
      <el-form :model="resetForm" status-icon :rules="step2rules" ref="step2rules" label-width="180px"
               class="demo-ruleForm" size="mini">
        <el-form-item label="申诉账号" >
          {{resetForm.account}}
        </el-form-item>
        <el-form-item label="输入密码" prop="password" placeholder="密码长度在6~20位之间">
          <el-col :span="9">
            <el-input type="password" v-model="resetForm.password" autocomplete="off"
                      placeholder="输入密码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="重复密码" prop="repeatPassword">
          <el-col :span="9">
            <el-input type="password" v-model="resetForm.repeatPassword" autocomplete="off"
                      placeholder="重复密码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitResetPassword">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="clear reset-step" :style="{'display': step === 3 ? 'block' : 'none'}" style="text-align: center;">
      <img style="margin-top: 20px;" src="/images/accountappeal/success.jpg"/>
      <div style="margin-top: 20px;">重置密码成功！</div>
      <div style="margin-top: 20px;">您已成功重置密码，可用新密码登录源码网，快去
        <router-link :to="'/login'" style="color: #F90;">登录</router-link>
        吧！
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accountAppealReset',
  components: {},
  data () {
    return {
      step: 1,
      resetForm: {
        account: null,
        resetCode: null,
        password: null,
        repeatPassword: null
      },
      step1rules: {
        account: [{required: true,trigger: 'blur',message:"账号不能为空"}],
        resetCode: [{
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value || value.length < 1) {
              callback(new Error('请输入回执码'))
            } else {
              callback()
            }
          }
        }]
      },
      step2rules: {
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6~20位之间', trigger: 'blur' }
        ],
        repeatPassword: [{
          required: true,
          validator: (rule, value, callback) => {
            this.resetForm.password === value ? callback() : callback(new Error('密码不一致'))
          }
        }]
      }
    }
  },
  methods: {
    async nextStep (ruleName) {
      if (await this.$refs[ruleName].validate()) {
        this.$request.post({
          reqcode: '1103',
          reqdesc: '检查回折码是否正确（回执码重置资料）',
          account: this.resetForm.account,
          resetCode: this.resetForm.resetCode
        }).then(res => {
          console.log(res)
          this.step++
        }).catch(res => {
        })

      } else {
        this.$message.warning('表单填写错误')
      }
    },
    async submitResetPassword () {
      if (await this.$refs.step2rules.validate()) {
        this.$request.post({
          reqcode: '1102',
          reqdesc: '回执码重置资料（密码）',
          ...this.resetForm
        }).then(res => {
          this.step++
        })
      } else {
        this.$message.warning('表单填写错误')
      }
    }
  }
}
</script>

<style scoped>
  input.el-input__inner {
    height: 30px;
  }

  .reset-step {
    padding: 50px;
    color: #7d7c7c;
  }

  .reset-step > .el-row {
    margin-top: 10px;
  }
</style>
